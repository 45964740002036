// @ts-check

import { Col, Container, Row } from 'react-bootstrap'
import { Outlet } from 'react-router-dom'
import ImpersonateModal from './impersonate/impersonate.modal.component'
import ImpersonatedUserTopBar from './impersonate/impersonated.user.top.bar.component'
import SideBar from './sidebar/sidebar.component'
import Footer from './sidebar/footer.component'
import Header from './header/header.component'
import useAuth from 'hooks/useAuth'
import { useImpersonation } from 'hooks/useImpersonation'

const MainContent = () => {
	const { currentCompany } = useAuth()
	const { isImpersonationModalOpen, closeImpersonationModal } =
		useImpersonation()

	return (
		<Container fluid className="d-flex flex-column flex-grow-1">
			{currentCompany?.isClient && (
				<ImpersonateModal
					show={isImpersonationModalOpen}
					onSelect={() => {
						closeImpersonationModal()
					}}
					onClose={() => {
						closeImpersonationModal()
					}}
				/>
			)}
			<div className="flex-grow-1">
				<Outlet />
			</div>

			<Footer />
		</Container>
	)
}

const Main = () => {
	const { impersonatedCustomer } = useImpersonation()

	return (
		<Container fluid className="d-flex flex-column min-vh-100">
			{impersonatedCustomer && (
				<Row>
					<ImpersonatedUserTopBar impersonatedCustomer={impersonatedCustomer} />
				</Row>
			)}
			<Row className="row-fluid flex-column flex-md-row flex-md-nowrap flex-grow-1">
				<Col className="sidenav" md="auto">
					<SideBar />
				</Col>
				<Col className="d-flex flex-column">
					<Header />
					<MainContent />
				</Col>
			</Row>
		</Container>
	)
}
export default Main
