import { Dropdown, Badge } from 'react-bootstrap'
import { formatCurrency, formatDate } from 'components/utilities'
import { CustomMenu, CustomToggle } from '../shared/custom.menu.component'

export const accessors = () => [
	{
		Header: 'Orders List',
		columns: [
			{
				Header: 'Order#',
				accessor: 'orderNum',
			},
			{
				Header: 'Status',
				accessor: (item) =>
					!item.openOrder ? (
						<Badge bg="secondary">Closed</Badge>
					) : item.orderHeld ? (
						<Badge bg="warning">Held</Badge>
					) : (
						<Badge bg="success">Open</Badge>
					),
			},
			{
				Header: 'PO#',
				accessor: 'pONum',
			},
			{
				Header: 'Ship Via',
				accessor: 'shipViaCodeDescription',
			},
			{
				Header: 'Order Date',
				accessor: (item) =>
					item.orderDate != null ? formatDate(item.orderDate) : '',
			},
			{
				Header: 'Due Date',
				accessor: (item) =>
					item.needByDate != null ? formatDate(item.needByDate) : '',
			},
			{
				Header: 'Order Amount',
				accessor: (item) =>
					formatCurrency(
						item.docTotalCharges + item.docTotalTax + item.docTotalMisc,
						item.currencyCodeCurrencyID,
					),
			},
			{
				Header: '⚡',
				accessor: (order) => order,
				Cell: ({ value: order, onPrintOrder }) =>
					!order.orderHeld && (
						<Dropdown>
							<Dropdown.Toggle
								as={CustomToggle}
								id="dropdown-custom-components"
							/>
							<Dropdown.Menu as={CustomMenu}>
								<Dropdown.Item
									eventKey="orderAck"
									onClick={() => {
										if (!order.orderHeld) {
											onPrintOrder(order.orderNum)
										}
									}}
									disabled={order.orderHeld}
								>
									Download Order
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					),
			},
		],
	},
]
