// @ts-check

import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react'
import { useSettingsService } from 'services/settings.service'
import { LoadingPage } from 'components/shared/loading/loading.page.component'

const SettingsContext = createContext(
	/** @type {SettingsContextValue | undefined} */ (undefined),
)

/**
 * @param {import('react').PropsWithChildren} props
 */
export function SettingsProvider({ children }) {
	const [settings, setSettings] = useState(
		/** @type {SettingsContextValue['settings'] | undefined} */ (undefined),
	)
	const SettingsService = useSettingsService()

	const reloadSettings = useCallback(async () => {
		const response = await SettingsService.findAll()
		setSettings(getSettingsObject(response.data))
		return response.data
	}, [SettingsService])

	const updateSettings = useCallback(
		async (/** @type {readonly Setting[]} */ newSettings) => {
			const response = await SettingsService.update(newSettings)
			setSettings(getSettingsObject(response.data))
			return response.data
		},
		[SettingsService],
	)

	useEffect(() => {
		reloadSettings()
	}, [reloadSettings])

	if (!settings) {
		return <LoadingPage message="Loading Settings" />
	}

	return (
		<SettingsContext.Provider
			value={{ settings, reloadSettings, updateSettings }}
		>
			{children}
		</SettingsContext.Provider>
	)
}

export function useSettings() {
	const context = useContext(SettingsContext)
	if (context === undefined) {
		throw new Error('useSettings must be used within a SettingsProvider')
	}

	return context
}

/**
 * @param {readonly Setting[]} settings
 */
const getSettingsObject = (settings) =>
	Object.fromEntries(settings.map((item) => [item.name, item.value]))
