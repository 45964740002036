import { Breadcrumb } from 'react-bootstrap'
import PaymentList from './payments.list.component'
import { useRefresher } from 'hooks/useRefresher'

const HeaderComponent = () => {
	return (
		<div className="header">
			<Breadcrumb className="d-inline-block" id="header-breadcrumbs">
				<Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
				<Breadcrumb.Item active>Payments</Breadcrumb.Item>
			</Breadcrumb>
		</div>
	)
}

const Payment = ({ stripeOnly = false }) => {
	const refresher = useRefresher()

	return (
		<>
			<HeaderComponent />
			<PaymentList refresher={refresher} stripeOnly={stripeOnly} />
			<refresher.Toaster />
		</>
	)
}

export default Payment
