// @ts-check

import { useMemo } from 'react'
import { useHttp } from 'hooks/useHttp'

class EpicorInvoiceService {
	/**
	 * @param {import('axios').AxiosInstance} http
	 */
	constructor(http) {
		this.#http = http
	}

	#http

	/**
	 * @param {number} customerId
	 */
	findAll(customerId) {
		return this.#http.get(`/api/customers/${customerId}/invoices`)
	}

	/**
	 * @param {number} customerId
	 * @param {string} invoiceNum
	 */
	getLines(customerId, invoiceNum) {
		return this.#http.get(
			`/api/customers/${customerId}/invoices/${invoiceNum}/lines`,
		)
	}

	/**
	 * @param {number} customerId
	 * @param {string} invoiceNum
	 */
	print(customerId, invoiceNum) {
		return this.#http.get(
			`/api/customers/${customerId}/invoices/${invoiceNum}/print`,
		)
	}

	/**
	 * @param {number} customerId
	 * @param {string} invoiceNum
	 * @param {{
	 *   currency: string
	 *   paymentAmount: number
	 *   paymentIntentId: string
	 *   paymentMethod: "card" | "us_bank_account"
	 * }} body
	 * @returns {AxiosReturn<{
	 *   clientSecret: string
	 *   paymentIntentId: string
	 * }>}
	 */
	paymentIntent(customerId, invoiceNum, body) {
		return this.#http.post(
			`/api/customers/${customerId}/payments/${invoiceNum}/preauth`,
			body,
		)
	}

	/**
	 * @param {number} customerId
	 * @param {string} invoiceNum
	 * @param {{
	 * 	paymentIntentId: string
	 * 	response: string
	 * 	success: boolean
	 * 	totalCharge: number
	 * 	currency: string
	 * }} body
	 */
	paymentCreate(customerId, invoiceNum, body) {
		return this.#http.post(
			`/api/customers/${customerId}/payments/${invoiceNum}`,
			body,
		)
	}
}

export function useEpicorInvoiceService() {
	const http = useHttp()
	return useMemo(() => new EpicorInvoiceService(http), [http])
}
