import { useCustomerId } from 'hooks/useAuth'
import { useCallback, useEffect, useState } from 'react'
import { Alert } from 'react-bootstrap'
import {
	useEpicorSharedService,
	useEpicorCustomerService,
} from 'services/epicor'
import { useCustomerService } from 'services/customer.service'
import { containerItem } from '../shared/styles'
import LoadingParagraphs from '../shared/loading/loading.paragraph.component'
import PaymentsTable from './payments.table.component'
import useAuth from 'hooks/useAuth'

const PaymentsList = ({ refresher, stripeOnly = false }) => {
	const [payments, setPayments] = useState([])
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)
	const [receiptMessage, setReceiptMessage] = useState(null)
	const customerId = useCustomerId()
	const EpicorSharedService = useEpicorSharedService()
	const EpicorCustomerService = useEpicorCustomerService()
	const CustomerService = useCustomerService()
	const { currentCompany } = useAuth()

	const fetchPayments = useCallback(async () => {
		setLoading(true)
		try {
			if (customerId && !stripeOnly) {
				const response = await EpicorSharedService.findAll(
					customerId,
					'payments',
				)
				setPayments(response.data)
			} else {
				const response = await CustomerService.paymentListCompany()
				setPayments(response.data.stripePayments)
			}
		} catch (error) {
			setError(error.message)
		} finally {
			setLoading(false)
		}
	}, [CustomerService, EpicorSharedService, customerId, stripeOnly])

	useEffect(() => {
		fetchPayments()
	}, [fetchPayments, refresher.id])

	const sendReceipt = (customerId, paymentIntentId) => {
		setError(null)
		setReceiptMessage(null)
		EpicorCustomerService.getPaymentReceipt(customerId, paymentIntentId)
			.then((response) => setReceiptMessage(response.data.message))
			.catch((error) => setError(error.message))
	}

	return (
		<div style={containerItem}>
			{error ? (
				<Alert dismissible variant="danger">
					{error}
				</Alert>
			) : null}
			{receiptMessage ? (
				<Alert dismissible variant="success">
					{receiptMessage}
				</Alert>
			) : null}
			{loading ? (
				<LoadingParagraphs cant={5} />
			) : (
				<PaymentsTable
					payments={payments}
					onRequestReceipt={sendReceipt}
					isClient={currentCompany.isClient}
				/> //TODO: Display Details on Click
			)}
		</div>
	)
}

export default PaymentsList
