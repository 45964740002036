// @ts-check

import { Col, Container, Row } from 'react-bootstrap'
import { useNavigate, useLocation } from 'react-router-dom'
import { BoxArrowRight } from 'react-bootstrap-icons'
import { useImpersonation } from 'hooks/useImpersonation'

/**
 * @param {{
 * 	impersonatedCustomer: Customer,
 * }} props
 */
const ImpersonatedUserTopBar = ({ impersonatedCustomer }) => {
	const navigate = useNavigate()
	const location = useLocation()
	const { unsetImpersonatedCustomer } = useImpersonation()
	const allowedRoutes = [
		'/home',
		'/users',
		'/settings',
		'/tickets',
		'/payments',
		'/epicor-connection',
	]
	return (
		<Container>
			<Row className="bg-red text-white">
				<Col className="my-auto mx-auto px-4">
					<div className="d-md-none">
						{impersonatedCustomer.name} ({impersonatedCustomer.custId})
					</div>
					<div className="d-none d-md-block">
						You are viewing as {impersonatedCustomer.name} (
						{impersonatedCustomer.custId})
					</div>
				</Col>
				<Col xs={2}>
					<u
						className="float-end"
						role="button"
						onClick={() => {
							unsetImpersonatedCustomer()
							if (!allowedRoutes.includes(location.pathname)) {
								navigate('/home')
							}
						}}
					>
						<BoxArrowRight
							className="d-md-none"
							aria-label="Exit View As Customer"
						/>
						<div className="d-none d-md-block">Exit View As</div>
					</u>
				</Col>
			</Row>
		</Container>
	)
}

export default ImpersonatedUserTopBar
