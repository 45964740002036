// @ts-check

import { Spinner } from 'react-bootstrap'

/**
 * @param {{
 * 	message: string,
 * }} props
 */
export const LoadingPage = ({ message }) => {
	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				gap: '1em',
				position: 'fixed',
				top: 0,
				left: 0,
				width: '100vw',
				height: '100vh',
				zIndex: 1000, // Ensure it is above other elements
			}}
		>
			<Spinner />
			{message}
		</div>
	)
}
