import { useMemo } from 'react'
import { useTable, useSortBy } from 'react-table'
import { Table as BTable } from 'react-bootstrap'
import { containerItem } from '../shared/styles'
import { accessors } from './users.table.accessors.component'

const UsersTable = ({ isClient, users, onUserDeleted, onEditUser }) => {
	const columns = useMemo(() => accessors(isClient), [isClient])
	const data = useMemo(
		() => users.filter((usr) => isClient === usr.isClient),
		[isClient, users],
	)
	const { getTableProps, headerGroups, rows, prepareRow } = useTable(
		{ columns, data, onUserDeleted, onEditUser },
		useSortBy,
	)

	return (
		<div style={containerItem}>
			<BTable responsive hover {...getTableProps()}>
				<thead>
					{headerGroups.map((headerGroup) => {
						const { key: headerGroupKey, ...headerGroupProps } =
							headerGroup.getHeaderGroupProps()
						return (
							<tr key={headerGroupKey} {...headerGroupProps}>
								{headerGroup.headers.map((column) => {
									const { key: headerKey, ...headerProps } =
										column.getHeaderProps(column.getSortByToggleProps())
									return (
										<th key={headerKey} {...headerProps}>
											{column.render('Header')}
											<span>
												{!column.isSorted
													? ''
													: column.isSortedDesc
														? ' ▼'
														: ' ▲'}
											</span>
										</th>
									)
								})}
							</tr>
						)
					})}
				</thead>
				<tbody>
					{rows.map((row) => {
						prepareRow(row)
						const { key: rowKey, ...rowProps } = row.getRowProps()
						return (
							<tr key={rowKey} {...rowProps}>
								{row.cells.map((cell) => {
									const { key: cellKey, ...cellProps } = cell.getCellProps()
									return (
										<td key={cellKey} {...cellProps}>
											{cell.render('Cell')}
										</td>
									)
								})}
							</tr>
						)
					})}
				</tbody>
			</BTable>
		</div>
	)
}

export default UsersTable
